import http from "../servicio/Servicio"
class RegistroServicio{
    getAll= (data) => {
        return http.get("/api/ad/get_all/"+data);
    }
    create=data=>{
        return http.post("/api/ad/create", data);
    }
    update=(id,data)=>{
        return http.put("/api/ad/update/"+id, data);
    }
    estado=data=>{
        return http.post("/api/ad/estado", data);
    }
    login=data=>{
        return http.put("/api/registro/login", data);
    }
    logout=data=>{
        return http.post("/api/registro/logout", data);
    }
      
}
export default new RegistroServicio();