import React, { Component } from "react";
import { classNames } from 'primereact/utils';
import { Fieldset } from 'primereact/fieldset';
import { Button } from 'primereact/button';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';
import { Tag } from 'primereact/tag';
import { Toolbar } from 'primereact/toolbar';
import { InputNumber } from 'primereact/inputnumber';
import { Dialog } from 'primereact/dialog';
import {Toast} from 'primereact/toast';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import servicio from '../servicio/RegistroServicio';
class Usuario extends Component{
    emptyRegistro = {
        id: null,
        carnet: 0,
        nombre:""
    };
    mesas = [
        { name: 'mesa1', code: 'mesa1' },
        { name: 'mesa2', code: 'mesa2' },
        { name: 'mesa3', code: 'mesa3' },
        { name: 'mesa4', code: 'mesa4' },
        { name: 'mesa5', code: 'mesa5' },
        { name: 'mesa6', code: 'mesa6' },
        { name: 'mesa7', code: 'mesa7' },
        { name: 'mesae', code: 'mesae' }
    ];
    nombre="USUARIOS"
    constructor(props) {
        super(props);
        this.state = {
            registros: null,
            registroDialog: false,
          
            registro: this.emptyRegistro,
            submitted: false,
            globalFilter: null,
            seleccion:'mesa1'
        };
        this.onUpdate=this.onUpdate.bind(this);
        this.bodyNro=this.bodyNro.bind(this);
        this.bodyAction=this.bodyAction.bind(this);
        this.bodyActivo=this.bodyActivo.bind(this);
        this.leftToolbarTemplate=this.leftToolbarTemplate.bind(this);
        this.openNew=this.openNew.bind(this);
        this.hideDialog=this.hideDialog.bind(this);
        this.onInputChange=this.onInputChange.bind(this);
        this.onCheckedChange=this.onCheckedChange.bind(this);
        this.registroDialogFooter=this.registroDialogFooter.bind(this);
        this.findIndexById=this.findIndexById.bind(this);
        this.saveRegistro=this.saveRegistro.bind(this);       
        this.editRegistro=this.editRegistro.bind(this);
        this.rightToolbarTemplate=this.rightToolbarTemplate.bind(this);
        this.onValueChange=this.onValueChange.bind(this);
        this.onChange=this.onChange.bind(this);

    }
    componentDidMount(){
        this.onUpdate();
    }

    editRegistro(registro) {
        this.setState({
            registro: { ...registro },
            registroDialog: true
        });
    }

    
    
    findIndexById(id) {
        let index = -1;
        for (let i = 0; i < this.state.registros.length; i++) {
            if (this.state.registros[i].id === id) {
                index = i;
                break;
            }
        }

        return index;
    }

    saveRegistro =async()=> {
        let state = { submitted: true };

        if (this.state.registro.nombre.trim()) {
            let registros = [...this.state.registros];
            let registro = {...this.state.registro};
            let registro2 = {...this.state.registro};
            if (registro.id) {
                var id=registro.id;
                delete registro.id;
                registro.mesa=this.state.seleccion;
                var response=await servicio.update(id,registro);                                
                var respuesta=response.data.registro;
                if(respuesta && respuesta[0]>=1){
                    const index = this.findIndexById(id);
                    registros[index] = registro2;
                    this.toast.show({ severity: 'success', summary: 'Exito', detail: 'Registro Actualizado', life: 3000 });
                }else{
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Registro no Actualizado', life: 3000 });
                }
                
            }else {   
                let registro = {...this.state.registro};
                registro.mesa=this.state.seleccion;
                var response=await servicio.create(registro);
                registro=response.data.registro;
                if(registro){
                    registros.push(registro);
                    this.toast.show({ severity: 'success', summary: 'Exito', detail: 'Registro Creado', life: 3000 });
                }else{
                    this.toast.show({ severity: 'error', summary: 'Error', detail: 'Registro no Creado', life: 3000 });
                }
            }
            state = {
                ...state,
                registros,
                registroDialog: false,
                registro: this.emptyRegistro
            };
        }

        this.setState(state);
    }
    registroDialogFooter(){
        return(
            <React.Fragment>
                <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={this.hideDialog} />
                <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={this.saveRegistro} />
            </React.Fragment>
        )
    }
    onInputNumberChange(e, name) {
        const val = e.value || 0;
        let registro = {...this.state.registro};
        registro[`${name}`] = val;

        this.setState({ registro });
    }
    onInputChange(e, name) {
        const val = (e.target && e.target.value) || '';
        let registro = {...this.state.registro};
        registro[`${name}`] = val;
        this.setState({ registro });
    }
    onCheckedChange(e, name) {
        const val = e.checked;
        let registro = {...this.state.registro};
        registro[`${name}`] = val;
        this.setState({ registro });
    }
    onValueChange(e) {
        const val=e.value;                
        this.state.seleccion=val;        
        this.setState({seleccion:this.state.seleccion});        
        this.onUpdate()
    }
    hideDialog() {
        this.setState({
            submitted: false,
            registroDialog: false
        });
    }

    openNew() {
        this.setState({
            registro: this.emptyRegistro,
            submitted: false,
            registroDialog: true
        });
    }
    onChange=async (id,value)=>{
        let registro = {
            id:id,
            estado:value
        };
        var response=await servicio.estado(registro);
        this.onUpdate();
    }
    bodyAction(rowData) {
        return (
            <React.Fragment>
                <Button icon="pi pi-pencil" tooltip="Editar" className="p-button-rounded p-button-success mr-2" onClick={() => this.editRegistro(rowData)} />                
            </React.Fragment>
        );
    }
    bodyActivo(rowData) {
        if(rowData.activo==true || rowData.activo==1){
            return (            
                <React.Fragment>
                    <Button label="Activo" icon="pi pi-check" className="p-button-success mr-2" onClick={()=>this.onChange(rowData.id,0)} /> 
                </React.Fragment>        
            );
        }else{
            return (            
                <React.Fragment>
                    <Button label="Inactivo" icon="pi pi-times" className="p-button-danger mr-2" onClick={()=>this.onChange(rowData.id,1)} />                     
                </React.Fragment>        
            );
        }
    }
    bodyNro(rowData,props){
        return props.rowIndex+1;
    }
    onUpdate(){
        servicio.getAll(this.state.seleccion).then(response =>{
            if(response.status===200&&response.data.status===true){                
                this.setState({                    
                    registros:response.data.registro
                });
            }else{
                this.setState({registros:[]});
            }
        });
    }
    leftToolbarTemplate(){
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-check" className="p-button-success mr-2" onClick={this.openNew} />                
            </React.Fragment>
        )
    }
    rightToolbarTemplate(){
        return (
            <React.Fragment>
                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.openNew} />                
            </React.Fragment>
        )
    }
    render(){        
        return (
            <Card>
                <h1>{this.nombre}</h1>     
                <Toast ref={(el) => this.toast = el} />           
                <Fieldset legend="REGISTRADOS">                
                    <Card >
                        <div className="card flex flex-column md:flex-row gap-3">
                            <div className="p-inputgroup">
                                <label htmlFor="mesa">Seleccione Mesa     </label>
                                <Dropdown  value={this.state.seleccion} options={this.mesas} onChange={(e)=>this.onValueChange(e)} optionLabel="name" optionValue="code"placeholder="Seleccione una mesa" />                        
                            </div>
                            <div className="p-inputgroup">
                                
                            </div>
                            <div className="p-inputgroup">
                                <Button label="Nuevo" icon="pi pi-plus" className="p-button-success mr-2" onClick={this.openNew} />       
                            </div>
                        </div>
                        
                        <br/>
                        <br/>                        
                        <DataTable value={this.state.registros} responsiveLayout="stack" breakpoint="960px">
                            <Column header="Nro" body={this.bodyNro}></Column>
                            <Column field="login" header="Login"/>
                            <Column field="carnet" header="Carnet" />
                            <Column field="nombre" header="Nombre" />
                            <Column field="activo" header="Activo" body={this.bodyActivo} />                                                   
                            <Column header="Acción" body={this.bodyAction}></Column>
                        </DataTable>
                    </Card>
                </Fieldset>
                <Dialog visible={this.state.registroDialog} style={{ width: '450px' }} header={"DETALLE "+this.nombre} modal className="p-fluid" footer={this.registroDialogFooter} onHide={this.hideDialog}>                    
                    <div className="field">
                        <label htmlFor="carnet">Carnet</label>
                        <InputNumber id="carnet" value={this.state.registro.carnet} onValueChange={(e) => this.onInputNumberChange(e, 'carnet')} integeronly="true" autoFocus/>
                    </div>
                    <input type="hidden" id="mesa" name="mesa" value={this.state.seleccion}></input>
                    <div className="field">
                        <label htmlFor="nombre">Nombre</label>
                        <InputText id="nombre" value={this.state.registro.nombre} onChange={(e) => this.onInputChange(e, 'nombre')} autoFocus />                        
                    </div>                    
                </Dialog>               
            </Card>           
        );
    }
}

export default Usuario;