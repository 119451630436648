//import logo from './logo.svg';
import './App.css';
import 'primereact/resources/themes/saga-blue/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.css';
import { Menubar } from 'primereact/menubar';
import { Route, useLocation,Routes } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import Registro from './componente/Usuario'
import Login from './componente/Login'
import Token from './componente/Token'
import servicio from './servicio/RegistroServicio'

/*
function App() {
    const navigate = useNavigate();    
    return (
        
        <div className="App">
            <header className="App-header">
               
            </header>
            <p></p>
            <p></p>
            <p></p>     
            <Routes><Route path="/" element={<Registro/>} /></Routes>                     
            
                            
            
        </div>
  );
}*/

function App() {
    const navigate = useNavigate();
    const logout= ()=>{
        if(Token.getData()!==null){
            var data={token:Token.getData().registro.token};
            servicio.logout(data).then((res)=>{
                Token.clear();
                navigate('/');
            });
        }
        
    }
    var items = [
        
        {
            label: 'Salir',
            icon: 'pi pi-fw pi-user-minus',     
            command: () => {logout()}       
        },
    ];
    const start = <img alt="logo" src="fdteusc.png" height="40" className="mr-2"></img>;
    var end="";
    if(Token.getData()!==null ){
        end = <p><b>Bienvenido: </b>{Token.getData().registro.login+" - "+Token.getData().registro.nombre}</p>
    }else{
        end="";
    }
    return (
        
        <div className="App">
            <header className="App-header">
               
            </header>
            <p></p>
            <p></p>
            <p></p>
            
                { (Token.getData()!==null && Token.getData().registro!==null)?(
                    <div className="card">
                        <Menubar model={items} start={start} end ={end}/>
                        <Routes>
                            <Route path="/" element={<Registro/>} />                                   
                        </Routes>
                    </div>
                ):(
                    <div className="card">
                        
                        <Routes>
                            <Route path="*" element={<Login/>} />                        
                        </Routes>
                    </div>
                )}
            
        </div>
  );
}

export default App;
